import $ from 'jquery';

import { AbortError } from 'cadenza/api-client/abort-controller/abort-error';
import { cadenzaUrl } from 'cadenza/utils/cadenza-url/cadenza-url';
import { errorAlert } from 'cadenza/alert';
import { logger } from 'cadenza/utils/logging';
import { HEADER_DISY_CANCEL_ID, HEADER_DISY_PROBLEM } from 'cadenza/api-client/api-constants';
import { SingleExecutionHelper } from 'cadenza/api-client/abort-controller/single-execution-helper';
import {
  BackendAbortController,
  isBackendAbortSignal
} from 'cadenza/api-client/abort-controller/backend-abort-controller';
import { extendUrlParameters, getUrlParameters } from 'cadenza/utils/url-param-utils';
import type { Problem } from 'cadenza/api-client/dto/problem-dto';
import { assert } from 'cadenza/utils/custom-error';

import i18n from './messages.properties';

export interface AjaxRequestParams {
  [key: string]: unknown;
}

export interface BackendException {
  key: string;
  title: string;
  type: string;
  status: number;
}

const UNKNOWN_SERVER_ERROR_ERROR_KEY = 'unknown-server-error';

/**
 * Allows to remember default ajax request data property via ajaxSetup function.
 */
let defaultAjaxRequestParams: object = {};

/**
 * Wrapper around $.ajax() for requesting our REST endpoints.
 * It makes requests cancelable via the provided signal promise.
 * It returns an ES6 promise that resolves to the parsed JSON result of the request in case of a
 * successful request and fails if the request results in an error. A detailed error object is
 * provided if it was available in the response body.
 * If the request failed because audit logging was unavailable, additionally an error alert with a
 * purposely vague message is displayed.
 *
 * @param url - The URL to request
 * @param settings - Request settings, see https://api.jquery.com/jquery.ajax/#jQuery-ajax-settings
 * @return Result promise.
 */
export function ajax<R> (url: string, settings: JQuery.AjaxSettings & { signal?: AbortSignal } = {}) {
  if (settings.signal) {
    assert(!settings.signal.aborted, 'Settings signal must not be aborted, already');
  }

  url = fillUrlWithDefaultParams(url);

  // for backend cancelable jobs (work orders)
  if (isBackendAbortSignal(settings.signal)) {
    settings.headers = Object.assign(settings.headers || {}, { [HEADER_DISY_CANCEL_ID]: settings.signal.id });
  }

  const jqXhr = $.ajax(cadenzaUrl(url), settings);

  if (settings.signal) {
    settings.signal.addEventListener('abort', () => jqXhr.abort());
  }

  return new Promise<R>((resolve, reject) => {
    jqXhr.then(
      // success
      result => {
        if (settings.signal?.aborted) {
          // Sometimes it can happen that the request was cancelled, but still managed to be executed.
          // Manually abort the results in such case.
          // Otherwise it can lead to a wrong state if we cancel the request, but still get its results.
          throw new AbortError();
        }
        if (isBackendAbortSignal(settings.signal)) {
          settings.signal.finished = true;
        }
        resolve(result);
      },
      // fail
      () => {
        defaultXhrErrorHandler(jqXhr, reject);
      }
    );
  }).finally(() => {
    if (settings.signal) {
      settings.signal.removeEventListener('abort', () => jqXhr.abort());
    }
  });
}

/**
 * Sets the default url parameters for every subsequent ajax() call. If ajax() has data passed
 * with the same properties as defaults, they will be overridden for that single request. To reset
 * the defaults, pass an empty object here.
 *
 * This function is analogical to jQuery's ajaxSetup(): https://api.jquery.com/jquery.ajaxsetup/
 * but operates only on url parameters
 *
 * @param defaultParams - Default url parameters (in map-like js object) for each next ajax() call.
 */
export function ajaxSetup (defaultParams: AjaxRequestParams) {
  defaultAjaxRequestParams = Object.assign({}, defaultParams);
}

export function fillUrlWithDefaultParams (url: string) {
  if (!defaultAjaxRequestParams) {
    return url;
  }
  const urlWithoutParams = url.split('?')[0];
  return extendUrlParameters(urlWithoutParams, { ...defaultAjaxRequestParams, ...getUrlParameters(url) });
}

export async function defaultXhrErrorHandler (xhr: JQuery.jqXHR, errorConsumer: (error: unknown) => void) {
  try {
    const problem = await extractProblemFromXhr(xhr);
    alertIfAuditLogError(problem);
    errorConsumer(problem);
  } catch (error) {
    if (!(error instanceof AbortError)) {
      logger.error('Unable to handle server error', error);
      errorConsumer(error);
    }
  }
}

/**
 * Builds an error descriptor object given a failed XMLHttpRequest or special jQuery XHR object.
 *
 * @param  xhr - the XMLHttpRequest or jqXHR wrapper
 * @return The error object
 */
export async function extractProblemFromXhr (xhr: XMLHttpRequest | JQuery.jqXHR): Promise<ResponseProblem> {
  // request has been canceled in client
  if (xhr.statusText === 'abort') { // statusText 'abort' set automatically by jqXhr.abort
    return new AbortError();
  }

  let responseProblem;

  // attempt to read xhr.responseText when not present might cause an error in some cases
  let responseText = null;
  try {
    responseText = xhr.responseText;
    // eslint-disable-next-line no-empty
  } catch (error) {}

  const errorHeaderValue = xhr.getResponseHeader(HEADER_DISY_PROBLEM);
  if (errorHeaderValue) { // if error header is present, use the value
    responseProblem = JSON.parse(atob(errorHeaderValue));
  } else if ('responseJSON' in xhr) { // jqXHR response body
    responseProblem = xhr.responseJSON;
  } else if (responseText) { // normal XHR response body
    try {
      responseProblem = JSON.parse(xhr.responseText);
    } catch (error) {
      logger.error('The error message does not seem to be JSON: ', xhr.responseText);
      // In case the proxy (e.g. Nginx) intercepts the request, it may not produce a JSON response.
      responseProblem = {
        key: UNKNOWN_SERVER_ERROR_ERROR_KEY,
        type: `http://disy.net/problem/${UNKNOWN_SERVER_ERROR_ERROR_KEY}`,
        title: 'An unknown error occurred.'
      };
    }
  } else if ('responseType' in xhr && xhr.responseType === 'blob') {
    // special blob request case, we have to parse the downloaded file (returns a promise)
    return await extractErrorFromXhrBlobResponse(xhr);
  }

  if (responseProblem?.type?.startsWith('http://disy.net/problem/')) { // NOSONAR "untrusted source" is only a namespace
    return Object.assign(responseProblem, { status: xhr.status });
  }

  return { status: xhr.status, content: ('responseJSON' in xhr && xhr.responseJSON) || xhr.responseText };
}

export type ResponseProblem = AbortError | Problem | {
  content?: object | string;
  status?: number;
};

function extractErrorFromXhrBlobResponse (xhr: XMLHttpRequest): Promise<ResponseProblem> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = function () {
      const errorDetails = JSON.parse(reader.result as string);
      errorDetails.status = xhr.status;
      resolve(errorDetails);
    };
    reader.onerror = reject;
    reader.readAsText(xhr.response);
  });
}

/*
 * Creates a new EventSource for the given relative url.
 * @param url Relative Cadenza url to the event source endpoint
 * @returns {EventSource}
 */
export function createEventSource (url: string) {
  return new EventSource(cadenzaUrl(url));
}

export function encodePathVariable (value: string) {
  //  CADENZA-14580 - To handle path params which contain '/' and make it possible to handle
  //  those values correctly by UrlPathHelper we'll encode those parameters as URIComponents and then with Base64 and
  //  remove the trailing padding '=' characters. Encoding only with Base64 would cause problems with handling umlauts and other unicode characters.
  //  The reason for removing padding characters is described in CADENZA-12272.
  return encodeToBase64WithoutPadding(encodeURIComponent(value));
}

const AUDIT_ERROR_KEY = 'audit-log-failed';

function alertIfAuditLogError (problem: ResponseProblem) {
  if (isAuditLogError(problem)) {
    errorAlert(i18n('api.errors.auditLogFailed.title'), i18n('api.errors.auditLogFailed.text'));
  }
}

export function isAuditLogError (problem: ResponseProblem) {
  return (problem as Problem).key === AUDIT_ERROR_KEY;
}

function encodeToBase64WithoutPadding (param: string) {
  return btoa(param).replace(/=+$/, '');
}

export function setArrayParamAsRepeatedParam (searchParams: URLSearchParams, paramName: string, arrayValue: unknown[], serializeToJson = false) {
  arrayValue.forEach(value => searchParams.append(paramName, serializeToJson ? JSON.stringify(value) : String(value)));
}

export function backendSingleExecution<T> (apiCallback: ApiCallback<T>) {
  return singleExecution<T>(apiCallback, true);
}

export function frontendSingleExecution<T> (apiCallback: ApiCallback<T>) {
  return singleExecution<T>(apiCallback, false);
}

/**
 * IMPORTANT: YOU SHOULD NOT CALL THIS FUNCTION EVERYTIME YOU WANT TO MAKE A NEW REQUEST, INSTEAD CALL THE RETURNED FUNCTION.
 *
 * Decorate the apiCallback function with abort & singleExecution capabilities.
 * The correct way to use this function is to call it one time in order to decorate your apiCallback,
 * and then always use the returned function.
 *
 * @example
 *   // last parameter of getData should be of type AbortSignal
 *   // function getData (..., abortSignal) { ... }
 *   const singleExecutionGetData = singleExecution(getData);
 *   singleExecutionGetData(param1, param2);
 *   singleExecutionGetData(param1, param2); // aborts the previous getData()
 * @example
 *   // Abort the current execution:
 *   singleExecutionGetData.cancelCurrent();
 * @param apiCallback - The ajax call which we want to decorate with singleExecution capabilities
 * @param isBackendAbort - This is just a flag to help us distinguish between the type of controller to use.
 * @param cancelTimeout - If set it causes request to be canceled after timeout.
 * true - will use the BackendAbortController which will send an actual request to cancel the job on the backend
 * false - will use the AbortController to cancel the request on the frontend only
 * @return - a function wrapping the apiCallback parameter which will cancel previous requests when used 2+ times.
 * The returned function also has a property function cancelCurrent which allows you to manually cancel a request
 */
export function singleExecution<T> (apiCallback: ApiCallback<T>, isBackendAbort = true, cancelTimeout = 0): CancelableApiCallback<T> {
  const singleExecutionHelper = new SingleExecutionHelper();
  const singleExecutionFn = function (this: unknown, ...args: unknown[]) {
    const abortController = isBackendAbort ? new BackendAbortController() : new AbortController();
    let isFinished = false;
    singleExecutionHelper.submit(abortController, () => isFinished);
    const timeoutCancelId = cancelTimeout ? setTimeout(() => singleExecutionHelper.cancelCurrent(), cancelTimeout) : undefined;
    const promise = apiCallback.apply(this, [ ...args, abortController.signal ]);
    if (isBackendAbort) {
      (abortController as BackendAbortController).abortOnUnload = promise;
    }

    return promise.finally(() => {
      if (timeoutCancelId) {
        clearTimeout(timeoutCancelId);
      }
      isFinished = true;
    });
  };
  return Object.assign(singleExecutionFn, { cancelCurrent: () => singleExecutionHelper.cancelCurrent() });
}

/** executes the callback sequentially (next call is waiting for the first one to complete) */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function sequentialExecution<T extends (...args: any[]) => Promise<any>> (callback: T) {
  let currentPromise: Promise<unknown> | undefined;
  return function (this: unknown, ...args: Parameters<T>) {
    currentPromise = Promise.allSettled([ currentPromise ])
      .then(() => callback.apply(this, args))
      .finally(() => {
        currentPromise = undefined;
      });
    return currentPromise as ReturnType<T>;
  };
}

export interface ApiCallback<T> {
  // last parameter should be of type AbortSignal
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (...args: any[]): Promise<T>;
}

export interface CancelableApiCallback<T> extends ApiCallback<T> {
  cancelCurrent: () => boolean;
}

/**
 * Utility function that tries to parse and create Date objects from the string value of the
 * specified fields of the given object.
 * If there is no value for the given field, nothing is done.
 * If there is a value but the parsing fails, an error is thrown.
 *
 * The purpose of this function is to call it on DTO objects received from the backend before they
 * are returned to the application code.
 *
 * @param dto - An object
 * @param dateFieldNames - Name of the properties that the function will attempt to parse and replace with Date objects.
 */
export function deserializeDateFields (dto: object, dateFieldNames: string[]) {
  const record = dto as Record<string, unknown>; // allow accessing properties dynamically
  dateFieldNames.forEach(dateFieldName => {
    const fieldValue = record[dateFieldName];
    if (fieldValue && typeof (fieldValue) === 'string') {
      try {
        record[dateFieldName] = new Date(fieldValue as string);
      } catch (error) {
        throw new Error(`Failed to parse date for field '${dateFieldName}'. Error: ${error}`);
      }
    }
  });
}
