const el = document.createElement('div');

export function escapeHTML (string: string | null = null) {
  el.textContent = string;
  return el.innerHTML;
}

export function escapeForAttribute (string: string) {
  return escapeHTML(string)
    .replaceAll('"', '&quot;')
    .replaceAll('\'', '&#39;');
}
