/**
 * This file intends to hold all of our parameter names to be used in our javascript api uniformly,
 * as well as custom media types.
 *
 * @see net/disy/cadenza/web/api/WebConstants.java
 * @see net/disy/lib/mediatype/MediaTypeValue.java
 */

export const MIMETYPE_DISY_HTML_FRAGMENT = 'text/html+fragment';
export const MIMETYPE_DISY_SKETCH = 'application/vnd.disy.sketch+json';
export const MIMETYPE_DISY_POST_MESSAGE = 'application/vnd.disy-postmessage+json';
export const MIMETYPE_DISY_SELFSERVICE_TABLE_COLUMNS = 'application/vnd.disy.self-service.table.columns+json';
export const MIMETYPE_DISY_SELFSERVICE_LAYER = 'application/vnd.disy.self-service.layer';
export const MIMETYPE_DISY_ROUTE = 'application/vnd.disy.route+json';
export const MIMETYPE_DISY_ISOCHRONE = 'application/vnd.disy.isochrone+json';
export const MIMETYPE_DISY_TIMELINE = 'application/vnd.disy.timeline+json';
export const MIMETYPE_DISY_WFS = 'application/vnd.disy.wfs+json';
export const MIMETYPE_DISY_GRID_SQUARE = 'application/vnd.disy.grid.square+json';

/* Accept header values to distinguish between "/layers/{layerId}/features" endpoints */
export const MIMETYPE_DISY_SELECTED_FEATURES = 'application/vnd.disy.selection.features+json';
export const MIMETYPE_DISY_VECTOR_LAYER_FEATURES = 'application/vnd.disy.vector-layer.features+json';
export const MIMETYPE_DISY_FEATURES_WITH_ATTACHMENTS = 'application/vnd.disy.attachment.features+json';
export const MIMETYPE_DISY_WORKORDER_ID = 'application/vnd.disy.workorder-id+text';

export const PARAMETER_DPI = 'DPI';

/**
 * @deprecated Prefer to use `HEADER_DISY_CANCEL_ID` as having the cancelId as a request param
 * essentially makes the request non-cacheable.
 */
export const PARAMETER_CANCEL_ID = 'cancelId';
export const PARAMETER_MIMETYPE = 'mimeType';
export const PARAMETER_CONDITION_HASH = 'conditionValuesSetHash';
export const PARAMETER_CONDITION_SET_ID = 'conditionSetId';
export const PARAMETER_CONDITION_DEFINITION_ID = 'conditionDefinitionId';
export const PARAMETER_PARTIAL = 'partial';
export const PARAMETER_GLOBAL_ID = 'globalId';
export const PARAMETER_REPOSITORY_ITEM_GLOBAL_ID = 'repositoryItemGlobalId';
export const PARAMETER_MAP_ID = 'mapId';
export const EXECUTION_CONFIRMED = 'executionConfirmed';
export const PARAMETER_PIVOT_CONFIG = 'pivot';
export const PARAMETER_MAP_SCALE = 'mapScale';
export const PARAMETER_MAP_EXTENT = 'mapExtent';
export const PARAMETER_MAP_SIZE = 'mapSize';
export const PARAMETER_MAP_RESOLUTION = 'mapResolution';
export const PARAMETER_SRS_CODE = 'srsCode';
export const PARAMETER_GEOMETRY = 'geometry';
export const PARAMETER_MAP_DISPLAY_MODE = 'mapDisplayMode';
export const PARAMETER_ROUTING_PROFILE = 'routingProfile';

export const HEADER_DISY_UPLOAD_TYPE = 'X-Disy-Upload-Type';
export const HEADER_DISY_MESSAGES = 'X-Disy-Messages';
export const HEADER_EXPORT_TYPE = 'X-Disy-Export-Type';
export const HEADER_POLL_REQUEST = 'X-Poll-Request';
export const HEADER_DISY_PROBLEM = 'X-Disy-Problem';

/**
 * @deprecated This is added automatically to an `ajax()` request
 * if you pass the `AbortSignal` of a `BackendAbortController`.
 */
export const HEADER_DISY_CANCEL_ID = 'X-Disy-Cancel-Id';

/* data URL with transparent PNG that can be used as image src for cancelling the loading of an image */
export const BLANK_IMAGE_SRC = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=';

/**
 * Initial map display modes
 *
 * @enum {string}
 * @readonly
 * @see net/disy/cadenza/gis/command/creation/InitialDisplayMode.java
 */
export const MapDisplayModes = {
  TIMELINE_2D: 'timeline2d',
  ROUTING: 'routing'
};

/**
 * @see net/disy/gis/layer/data/query/MapObjectSelection
 */
export type MapObjectSelection = 'ONLY_SELECTED_OBJECTS' | 'ALL_LOADED_OBJECTS' | 'ALL_OBJECTS_FROM_DATASOURCE';
