export type CadenzaUrl = string;

/*
 * Prefixes relative URLs with the context path and maintains the jsessionid param.
 *
 * See net/disy/cadenza/web/util/UrlEncodingUtilities.java
 */
export function cadenzaUrl (url: string): CadenzaUrl {
  if (!url) {
    return '';
  }

  const jsessionid = getJsessionidParam(location.href);

  if (isExternal(url)) {
    if (jsessionid) {
      return removeCadenzaJsessionidParam(url, jsessionid);
    }
    return url;
  }

  const { contextPath, cookielessMode } = window.Disy;
  if (!url.startsWith(contextPath)) {
    url = contextPath + url;
  }
  if (cookielessMode && jsessionid) {
    url = setJsessionidParam(url, jsessionid);
  }
  return url;
}

export function isExternal (url: string) {
  return /^[a-z]+:/i.test(url);
}

function removeCadenzaJsessionidParam (url: string, jsessionid: string) {
  return url.replace(`;jsessionid=${jsessionid}`, '');
}

function setJsessionidParam (url: string, jsessionid: string) {
  return url.replace(/(;jsessionid=\w+)?(\?|$)/, `;jsessionid=${jsessionid}$2`);
}

function getJsessionidParam (url: string) {
  return url.match(/;jsessionid=(\w+)/)?.[1];
}
