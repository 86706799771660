import h from 'hyperscript';

import { createAnchorButton } from 'ui/button/button';

import { logger } from 'cadenza/utils/logging';

/**
 * Applies a limited subset of Asciidoc formatting to a given text and outputs HTML.
 *
 * Supported:
 * - bold and italic text
 * - links
 * - line breaks and paragraphs
 *
 * @param str - Text which will be enhanced
 * @param leadingP - Should message be enhanced with <p> at the beginning
 * @return enhanced string with HTML tags
 * @see Published {@link https://jira.disy.net/browse/DR-293|DR-293} Customers may use this in their help tooltips.
 */
export function mark (str: string, leadingP = true) {
  return (leadingP ? '<p>' : '') + str
    .replace(/\n{2,}/g, '<p>')
    .replace(/\n/g, '<br>')
    .replace(/\*\*(?<text>([^*]|\*(?!\*))+)\*\*/g, '<b>$<text></b>')
    .replace(/__(?<text>([^_]|_(?!_))+)__/g, '<i>$<text></i>')
    .replace(/link:(?<href>[^[]+)\[(?<text>[^\]]+)]/g, '<a href="$<href>">$<text></a>');
}

interface MarkOptions {
  /** Should message be enhanced with <p> at the beginning */
  leadingP?: boolean;
  /** Mapping from anchor name to its callback function */
  anchorsConfig?: Record<string, unknown>;
}

/**
 * Adds click event handlers for the anchor definitions contained in {@code str}.
 * Additionally {@code str} will be enhanced with {@code mark} function.
 *
 * @param str - Text which will be enhanced
 * @param options - options for marking
 * @return all the elements created during enhancement process
 */
export function markAllWithAnchors (str: string, { leadingP = false, anchorsConfig = {} }: MarkOptions = {}) {
  const template = h('div');
  /* eslint-disable-next-line no-unsanitized/property */
  template.innerHTML = mark(str, leadingP);
  Object.entries(anchorsConfig).forEach(([ anchorName, anchorClickCallback ]) => {
    const anchorPlaceholder = template.querySelector<HTMLAnchorElement>(`a[name=${anchorName}]`);
    if (anchorPlaceholder) {
      const configuredAnchorButton = createAnchorButton(anchorPlaceholder.textContent ?? '', { onclick: anchorClickCallback });
      anchorPlaceholder.replaceWith(configuredAnchorButton);
    } else {
      logger.warn(`Provided text was supposed to contain anchor with name: "${anchorName}".`);
    }
  });
  return [ ...template.childNodes ];
}
